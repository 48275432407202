div.legal {
  h1 {
    @apply text-[24px] text-center;
  }
  h1 + p {
    @apply text-[20px] text-center text-[#6B7280] w-full max-w-[768px] mx-auto;
  }
  h2 {
    @apply text-[24px] text-[#249198];
  }
}
