@import "tailwindcss/base";
@import "tailwindcss/components";
@import "../flashnotice.scss";
.abc {
  display: none;
}
.btn {
  @apply px-4 py-2 bg-red-600 text-white;
}
@import "tailwindcss/utilities";
body {
}
#hero {
  background-image: url("../../images/bg.jpeg");
  background-size: cover;
}
#product_nav {
  li {
    @apply hover:text-jumb_color;
    @apply text-gray-500;
    @apply ml-0;
  }

  ul.act {
    li:nth-child(1) {
      @apply text-jumb_color;
    }
  }
  ul.actstory {
    li:nth-child(2) {
      @apply text-jumb_color;
    }
  }
  ul.actopening {
    li:nth-child(3) {
      @apply text-jumb_color;
    }
  }
  ul.actchildhood-montage {
    li:nth-child(4) {
      @apply text-jumb_color;
    }
  }
  ul.actlovestory {
    li:nth-child(5) {
      @apply text-jumb_color;
    }
  }
  ul.actthank-you {
    li:nth-child(6) {
      @apply text-jumb_color;
    }
  }
  ul.actwedding-slideshow {
    li:nth-child(7) {
      @apply text-jumb_color;
    }
  }
}
.vt-shadow {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 5px 5px rgba(0, 0, 0, 0.06);
}
#vt-products .item {
  .thumb {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  p {
    @apply leading-none;
  }
}

#home-guideline {
  h2 {
    @apply text-2xl font-extrabold leading-none tracking-tight text-main_pink   sm:text-2xl sm:leading-[3.5rem];
  }

  .guideline-body {
    li {
      @apply ml-7;
      img {
        width: 100%;
        max-width: none;
        @apply mt-2 mb-10;
      }
    }
    img {
      @apply mt-2 mb-10;
    }
  }
}
