#dashboard_menu {
  #films_all.films {
    @apply bg-black text-white;
  }
  #films_produced.produced {
    @apply bg-black text-white;
  }
  #films_complete.complete {
    @apply bg-black text-white;
  }
  #user_profile.profile {
    @apply bg-black text-white;
  }
  #user_edit_pwd.change_password {
    @apply bg-black text-white;
  }
  #user_order.orders {
    @apply bg-black text-white;
  }
}
@mixin base_dashboard_btn {
  @apply text-center mt-[15px] h-[38px] w-full inline-flex justify-center items-center px-2.5 py-1.5 border border-transparent text-base font-medium rounded-[6px] shadow-sm text-white  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
}
#user-dashboard {
  .btn.edit {
    @include base_dashboard_btn();
    @apply bg-[#FF7791];
  }
  .btn.trial_rendering {
    @include base_dashboard_btn();
    @apply bg-[#FF7791];
  }
  .btn.preview {
    @include base_dashboard_btn();
    @apply bg-yellow-300;
  }
  .btn.formal_rendering {
    @include base_dashboard_btn();
    @apply bg-[#eee];
  }
  .btn.download {
    @include base_dashboard_btn();
    @apply bg-[#5DC6CD];
  }
}
